import React from "react";
import "../styles/Footer.css";
import "../styles/NavBar.css";
import { Link } from "gatsby";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>About Us</h2>
            <Link to="/Reviews">Testimonials</Link>
            <Link to="/Schedule">Partner with us</Link>
          </div>
          <div className="footer-link-items">
            <h2>Contact Us</h2>
            <Link to="/Contact">Contact</Link>
            <Link to="/Schedule">Join our team</Link>
            <Link to="/Faq">FAQ</Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Social Media</h2>
            <a
              href="https://www.facebook.com/pointblankmoving/?ref=py_c"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
            <a
              href="https://www.tiktok.com/@point.blank.moving?lang=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              TikTok
            </a>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/Home" className="footer-logo">
              point.blank <br /> moving
            </Link>
          </div>
          <small className="website-rights">point.blank © 2020</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;

import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import "../styles/NavBar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import DropDown from "./DropDown";
import { IconContext } from "react-icons/lib";
import Seo from "./Seo";

const NavBar = ({ title, description, image, article }) => {
  const isBrowser = typeof window !== "undefined";
  const [navBackground, setNavBackground] = useState("navbar");

  useEffect(() => {
    if (!isBrowser) return false;

    const handleScroll = () => {
      if (window.scrollY > 100) {
        setNavBackground("navbar-scrolled");
      } else {
        setNavBackground("navbar");
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => {
    setClick(false);
  };

  return (
    <>
      <Seo title={title} description={description} />
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav className={navBackground}>
          <div className="navbar-container container">
            <Link
              to="/"
              style={{ fontFamily: "Special Elite, cursive" }}
              className="navbar-logo"
              onClick={closeMobileMenu}
            >
              point.blank <br /> moving
            </Link>
            <div className="menu-icon" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/About"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/Services"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/Reviews"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Reviews
                </Link>
              </li>
              <li
                className="nav-item"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Link
                  to="/Locations"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Locations
                </Link>
                {dropdown && <DropDown />}
              </li>
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
};

export default NavBar;
